<template>
  <div class="CollaborateurTasks">

    <!-- MODIFY TASK -->
    <CModal
      :show.sync="isTaskModifying"
      :no-close-on-backdrop="true"
      title="Modifier votre tâche"
      size="lg"
      color="dark"
    >
      <CForm>
        <CSelect
          label="Votre mission"
          :value.sync="modifyTaskMission"
          :options="missionsSelectOptions"
          @input="$v.modifyTaskMission.$touch()"
          :isValid="$v.modifyTaskMission.$dirty ? !$v.modifyTaskMission.$error : null"
          custom
        />
        <CRow>
          <CCol>
            <label>Date <small class="text-danger mb-4" v-if="$v.modifyTaskDateFormat.$invalid ">Veuillez saisir une date valide antérieure à aujourd'hui</small></label>
            <CRow>
              <CCol sm="3">
                <CSelect
                  :value.sync="modifyTaskDateDay"
                  :options='daysArrayForm'
                  custom
                />
              </CCol>
              <CCol sm="6">
                <CSelect
                  :value.sync="modifyTaskDateMonth"
                  :options='monthsArrayForm'
                  custom
                />
              </CCol>
              <CCol sm="3">
                <CSelect
                  :value.sync="modifyTaskDateYear"
                  :options='yearsArrayForm'
                  custom
                />
              </CCol>
            </CRow>


          </CCol>
        </CRow>
        <CRow>
          <CCol sm="6">
            <CInput
              label="Heures"
              type="number"
              v-model="modifyTaskHours"
              @input="$v.modifyTaskHours.$touch()"
              :isValid="$v.modifyTaskHours.$dirty ? !$v.modifyTaskHours.$error : null">
            </CInput>
          </CCol>
          <CCol sm="6">
            <CInput
              label="Minutes"
              type="number"
              v-model="modifyTaskMinutes"
              @input="$v.modifyTaskMinutes.$touch()"
              :isValid="$v.modifyTaskMinutes.$dirty ? !$v.modifyTaskMinutes.$error : null">
            </CInput>
          </CCol>
        </CRow>

        <CTextarea
          label="Courte description"
          v-model="modifyTaskDescription" type="text"
          @input="$v.modifyTaskDescription.$touch()"
          :isValid="$v.modifyTaskDescription.$dirty ? !$v.modifyTaskDescription.$error : null"
        >
        </CTextarea>

        <CRow class="mt-4">
          <CCol class="text-center">
            <CButton
              :color="modifyTaskButtonStyle"
              shape="pill" block class="px-4"
              :disabled="$v.modifyTaskMission.$invalid || $v.modifyTaskDateFormat.$invalid || $v.modifyTaskHours.$invalid || $v.modifyTaskMinutes.$invalid || $v.modifyTaskDescription.$invalid || modifyTaskInProcess"
              @click='modifyTask'>
              <CSpinner size="sm" label="update task spinner" v-if="modifyTaskInProcess"></CSpinner>
              {{ modifyTaskButtonText }}
            </CButton>
          </CCol>
        </CRow>
      </CForm>

      <template #footer>
        <CButton @click="isTaskModifying = false" color="dark">Annuler</CButton>
      </template>
    </CModal>


    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="black">
    </loading>

    <CRow>
      <CCol>
        <h1> Votre reporting journalier </h1>
      </CCol>
    </CRow>

    <CCard class="mt-3">
      <CCardBody>
        <CRow>
          <CCol>
            <strong>{{$dayjs(filterTasksDate).format('dddd D MMMM YYYY') | capitalize }}</strong>
          </CCol>
        </CRow>

        <CRow class="mt-2">
          <CCol sm="4">
            <CSelect
              :value.sync="filterTaskDay"
              :options='daysArrayForm'
              :horizontal='true'
              label="Date"
              custom
            />
          </CCol>
          <CCol sm="5">
            <CSelect
              :value.sync="filterTaskMonth"
              :options='monthsArrayForm'
              custom
            />
          </CCol>
          <CCol sm="3">
            <CSelect
              :value.sync="filterTaskYear"
              :options='yearsArrayForm'
              custom
            />
          </CCol>
        </CRow>

        <CRow>
          <CCol>
            <a class="eclerk-link" href="#" @click="goToPreviousDay">
                <CIcon name="cil-arrow-left"/> Jour précédent
            </a>

            <a class="ml-1 eclerk-link" href="#" @click="goToNextDay">
              Jour suivant <CIcon name="cil-arrow-right"/>
            </a>

            <CButton
              v-if="!isToday"
              color="outline-dark"
              shape="pill"
              @click="goToday"
              class="ml-1"
            >
              Aujourd'hui
            </CButton>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <CCard>
      <CCardHeader>
        <strong>⏰ Ajouter une nouvelle tâche</strong>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol>
            <b-form v-on:submit.prevent="addTask(newTasks.mission_id, newTasks.hours, newTasks.minutes, newTasks.description)">
              <CDataTable
                addTableClasses="no-margin-bottom-table"
                :header="true"
                headVariant="light"
                :items="[newTasks]"
                :fields="newTaskRenderFields"
              >
              <template #mission_id="">
                <td>
                  <CSelect
                    :value.sync="newTasks.mission_id"
                    placeholder="Votre mission"
                    :options="missionsSelectOptions"
                    @input="$v.newTasks.mission_id.$touch()"
                    :isValid="$v.newTasks.mission_id.$dirty ? !$v.newTasks.mission_id.$error : null"
                    custom
                  />
                </td>
              </template>
              <template #hours="">
                <td>
                  <CInput
                    placeholder="Heures"
                    type="number"
                    v-model="newTasks.hours"
                    @input="$v.newTasks.hours.$touch()"
                    :isValid="$v.newTasks.hours.$dirty ? !$v.newTasks.hours.$error : null">
                  </CInput>
                </td>
              </template>
              <template #minutes="">
                <td>
                  <CInput
                    placeholder="Minutes"
                    type="number"
                    v-model="newTasks.minutes"
                    @input="$v.newTasks.minutes.$touch()"
                    :isValid="$v.newTasks.minutes.$dirty ? !$v.newTasks.minutes.$error : null">
                  </CInput>
                </td>
              </template>
              <template #description="">
                <td v-if="isNewTaskAddingBigDescription == false" style="min-width:350px">
                  <CInput
                    style="margin-bottom: 0px;"
                    placeholder="Description précise"
                    type="text"
                    maxlength="150"
                    v-model="newTasks.description"
                    @input="$v.newTasks.description.$touch()"
                    :isValid="$v.newTasks.description.$dirty ? !$v.newTasks.description.$error : null">
                  </CInput>
                  <CButton @click="isNewTaskAddingBigDescription = true" size="sm" color="outline-primary" class="mt-2">
                    Ajouter une description sur plusieurs lignes
                  </CButton>
                </td>
                <td v-else style="min-width:350px">
                  <CTextarea
                    placeholder="Description précise"
                    type="text"
                    maxlength="500"
                    rows="5"
                    v-model="newTasks.description"
                    @input="$v.newTasks.description.$touch()"
                    :isValid="$v.newTasks.description.$dirty ? !$v.newTasks.description.$error : null">
                  </CTextarea>
                </td>
              </template>
              <template #actions="">
                <td>
                  <CButton
                    v-if="!isFutur"
                    type="submit"
                    :color="addTaskButtonStyle"
                    square
                    :disabled="$v.newTasks.$invalid || addTaskInProcess"
                  >
                      {{addTaskButtonText}}
                  </CButton>
                  <CButton
                    v-else
                    color="dark"
                    square
                    :disabled="true">
                      Pas d'ajoût de tâches en avance
                  </CButton>
                </td>
              </template>
              </CDataTable>
            </b-form>
          </CCol>
        </CRow>

        <CRow style="margin-top: -0.8rem;">
          <CCol>
            Temps total travaillé sur la journée : <b>{{ totalWorkingTime.workedHours }} heures et {{ totalWorkingTime.workedMinutes }} minutes </b>
          </CCol>
        </CRow>

        <CRow class="mt-3">
          <CCol>
            <CDataTable
              headVariant="light"
              :items="tasksRender"
              :fields="tasksRenderFields"
              :noItemsView='{ noResults: "Aucune tâche", noItems: "Aucune tâche" }'
            >
            <template #hours="{item}">
              <td class="text-center">
               {{item.hours}}
              </td>
            </template>
            <template #minutes="{item}">
              <td class="text-center">
               {{item.minutes}}
              </td>
            </template>
            <template #description="{item}">
              <td style="white-space: pre-line;">
               <small>{{item.description}}</small>
              </td>
            </template>
            <template #actions="{item}">
              <td>
                <CButton
                  class="ml-2"
                  square
                  @click="showModifyTask(item)">
                  <CIcon class="text-primary" name="cil-pencil"/>
                </CButton>
                <CButton
                  class="ml-2"
                  square
                  :disabled="deleteTaskInProcess"
                  @click="deleteTask(item.id)">
                  <CIcon class="text-danger" name="cil-trash"/>
                </CButton>
              </td>
            </template>

            <template #no-items-view>
              <CRow>
                <CCol class="text-center">
                  Aucune tâche enregistrée
                </CCol>
              </CRow>
            </template>
            </CDataTable>

            <CRow v-if="managerAlertMessage">
              <CCol>
                <p style="color:red;">
                 <CIcon name="cil-bell" class="mr-2"></CIcon>
                 <strong>Alerte laissé par un manager : </strong><br>
               </p>
               <p>
                 {{managerAlertMessage}}
               </p>
              </CCol>
            </CRow>
          </CCol>
        </CRow>

      </CCardBody>
    </CCard>

    <CCard >
      <CCardHeader>
        <strong>Résumé de votre semaine</strong>
      </CCardHeader>
      <CCardBody>
        <table class="table text-center">
          <thead>
            <tr>
              <th v-for="(date, index) in lastWeekTasksRenderDates" v-bind:key="index"
                class="thead-clickable" v-on:click="filterTasksDate = date"
                >
                {{$dayjs(date).format('D MMM YYYY')}}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th v-for="(value, index) in lastWeekTasksRenderValues" v-bind:key="index">{{value}}</th>
            </tr>
          </tbody>
        </table>
      </CCardBody>
    </CCard>
    <CRow>
      <CCol sm="6">
        <CCard>
          <CCardHeader>
            <strong>Vision consolidée de votre semaine</strong>
          </CCardHeader>
          <CCardBody>
            <doughnutChart
              :chart-data="{labels: lastWeekTasksDoghnutRenderMission, datasets: lastWeekTasksDoghnutRenderTime}"
              :options="doughnutChartOptions"
            />
          </CCardBody>
        </CCard>
      </CCol>
      <CCol sm="6">
        <CCard>
          <CCardHeader>
            <strong>Vision détaillée de votre semaine</strong>
          </CCardHeader>
          <CCardBody>
            <barChart
              :chart-data="{labels: lastWeekTasksRenderDates, datasets: lastWeekTasksBarChartRenderTime}"
              :options="barChartOptions">
            </barChart>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>




  </div>
</template>

<script>
import { APIUserConnected } from '@/api/APIUserConnected'
import { timeOperations } from '@/mixins/utilMixins'
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, integer, minValue, maxValue, requiredIf} from 'vuelidate/lib/validators'
import { chartBackgroundColorsArray } from '@/variables/chartBackgroundColors'

import barChart from '@/components/chartComponents/barChart'
import doughnutChart from '@/components/chartComponents/doughnutChart'

import Loading from 'vue-loading-overlay';

import 'vue-loading-overlay/dist/vue-loading.css';

import { isDate, isValidDateBeforeToday } from '@/validators/validators'

const apiUserConnected = new APIUserConnected()

export default {
  name: 'CollaborateurTasks',
  components: {
    Loading,
    barChart,
    doughnutChart,
  },
  mixins: [
    timeOperations,
    validationMixin,
  ],
  data: function () {
    const today = this.$dayjs()
    const todayYear = today.format('YYYY')
    const todayMonth = today.format('MM')
    const todayDay = today.format('DD')
    return {

      // ------------- COLLABORATEUR ---
      managerAlertMessage:  '',
      isProfileLoading: false,

      // ------------- TASKS -----------
      filterTaskDay: todayDay,
      filterTaskMonth: todayMonth,
      filterTaskYear: todayYear,


      tasks: [],
      tasksRender: [],
      tasksRenderFields: [
        { key: "mission_name", label: "Mission", _style: "min-width: 200px;"},
        { key: "hours", label: "Heures", _style: "min-width: 100px;"},
        { key: "minutes", label: "Minutes", _style: "min-width: 100px;"},
        { key: "description", label: "Description", _style: "min-width: 250px;"},
        { key: "actions", label: "", _style: "min-width: 200px;"},
      ],
      isAllCollaborateurTasksLoading: false,

      // ------------- LAST WEEK TASKS -------
      isAllCollaborateurLastWeekTasksLoading: false,
      lastWeekTasks: [],
      lastWeekTasksRenderDates: [],
      lastWeekTasksRenderValues: [],

      // ------------ DOGHNUT LAST WEEK TASK -----
      lastWeekTasksDoghnutRenderMission: [],
      lastWeekTasksDoghnutRenderTime: [{
        label: 'Répartition par mission',
        borderWidth: 2,
        data: [],
        backgroundColor: []
      }],
      doughnutChartOptions: {
        title: {
           display: true,
           text: 'Répartition de vos missions (en heures)'
        },
        legend: {
          display: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            color: 'white',
            display: function (context) {
              return context.dataset.data[context.dataIndex] > 0
            },
            font: {
              weight: 'bold'
            },
          }
        }
      },

      // ------------ BAR CHART LAST WEEK TASK
      lastWeekTasksBarChartRenderTime: [],
      barChartOptions: {
        title: {
           display: true,
           text: 'Répartition journalière de vos missions (en heures)'
        },
        scales: {
          xAxes: [{
            stacked: true,
            gridLines: {
              display: false
            },
            ticks: {
              autoSkip: false
            }
          }],
          yAxes: [{
            display: false,
            stacked: true,
            gridLines: {
              display: false
            }
          }]
        },
        legend: {
          display: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            color: 'white',
            display: function (context) {
              return context.dataset.data[context.dataIndex] > 0
            },
            font: {
              weight: 'bold'
            },
          }
        }
      },

      // ------------- MISSION -----------
      missions: [],
      missionsSelectOptions: [],
      isAllCollaborateurMissionsLoading: false,

      // ------------ ADD TASK --------
      newTaskRenderFields: [
        { key: "mission_id", label: "", _style: "min-width: 200px;"},
        { key: "hours", label: "", _style: "min-width: 100px;"},
        { key: "minutes", label: "", _style: "min-width: 100px;"},
        { key: "description", label: "", _style: "min-width: 250px;"},
        { key: "actions", label: "", _style: "min-width: 200px;"},
      ],
      newTasks: {
        'mission_id': '',
        'hours': '',
        'minutes': '',
        'description': ''
      },

      isNewTaskAddingBigDescription: false,

      addTaskButtonText: 'Ajouter une tâche',
      addTaskButtonStyle: 'dark',
      addTaskInProcess: false,

      // ---------- MODIFY TASK ---
      isTaskModifying: false,

      modifyTaskId: 0,
      modifyTaskMission: '',
      modifyTaskDateDay: '01',
      modifyTaskDateMonth: '01',
      modifyTaskDateYear: '2020',
      modifyTaskHours: '',
      modifyTaskMinutes: '',
      modifyTaskDescription: '',

      modifyTaskButtonText: 'Modifier votre tâche',
      modifyTaskButtonStyle: 'outline-dark',
      modifyTaskInProcess: false,

      monthsArrayForm: [
          { label: 'Janvier', value: '01' },
          { label: 'Février', value: '02' },
          { label: 'Mars', value: '03' },
          { label: 'Avril', value: '04' },
          { label: 'Mai', value: '05' },
          { label: 'Juin', value: '06' },
          { label: 'Juillet', value: '07' },
          { label: 'Août', value: '08' },
          { label: 'Septembre', value: '09' },
          { label: 'Octobre', value: '10' },
          { label: 'Novembre', value: '11' },
          { label: 'Décembre', value: '12' },
        ],

      // ---------- DELETE TASK ---
      deleteTaskButtonText: 'Supprimer',
      deleteTaskButtonStyle: 'outline-danger',
      deleteTaskInProcess: false,
    }
  },

  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isLoading () {
      if (this.isAllCollaborateurTasksLoading || this.isAllCollaborateurMissionsLoading || this.isAllCollaborateurLastWeekTasksLoading || this.isProfileLoading) {
        return true
      }
      return false
    },
    isToday () {
      var today = this.$dayjs()
      return this.filterTasksDate == today.format('YYYY-MM-DD')
    },
    isFutur () {
      var today = this.$dayjs()
      var dayFilterDate = this.$dayjs(this.filterTasksDate)
      var difference = today.diff(dayFilterDate)
      return difference < 0
    },
    totalWorkingTime () {
      var total_hours = 0
      var total_minutes = 0
      for (var i = 0; i < this.tasks.length; i++) {
        total_hours = total_hours + this.tasks[i].hours
        total_minutes = total_minutes + this.tasks[i].minutes
      }
      var result = this.calculateTotalDuration(total_hours, total_minutes)
      return {'workedHours': result.hours, 'workedMinutes': result.minutes}
    },

    // ---- Date
    daysArrayForm () {
      var days = []
      for (var i=1; i<=31; i++) {
        var value = String(i)
        if (i <= 9) {
          value = "0" + String(i)
        }
        days.push({'text': value, 'value': value})
      }
      return days
    },

    yearsArrayForm () {
      var today = this.$dayjs()
      var maxYear = today.format('YYYY')

      var years = []
      for (var i=1990; i<=maxYear; i++) {
        var value = String(i)
        years.push({'text': value, 'value': value})
      }
      return years
    },

    modifyTaskDateFormat () {
      return this.modifyTaskDateYear + '-' + this.modifyTaskDateMonth + '-' + this.modifyTaskDateDay
    },

    filterTasksDate () {
      return this.filterTaskYear + '-' + this.filterTaskMonth + '-' + this.filterTaskDay
    }
  },
  validations: {
    newTasks: {
      mission_id: {
        required
      },
      hours: {
        integer,
        minValue: minValue(0),
        maxValue: maxValue(23)
      },
      minutes: {
        required: requiredIf(function () {
          return this.newTasks.hours == ''
        }),
        integer,
        minValue: minValue(0),
        maxValue: maxValue(59)
      },
      description: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(300)
      },
    },
    modifyTaskMission: {
      required
    },
    modifyTaskDateFormat: {
      required,
      validDateBeforeToday(value) {
        return isValidDateBeforeToday(value)
      }
    },
    modifyTaskHours: {
      required,
      integer,
      minValue: minValue(0),
      maxValue: maxValue(23)
    },
    modifyTaskMinutes: {
      required,
      integer,
      minValue: minValue(0),
      maxValue: maxValue(59)
    },
    modifyTaskDescription: {
      required,
      minLength: minLength(5),
      maxLength: maxLength(300)
    },
  },
  created: function() {
    this.$dayjs.locale('fr')
    this.connectedCollaborateurAllMissions()
    this.getCollaborateurTasks()
    this.getCollaborateurLastWeekTasks()
    this.getConnectedCollaborateur()
  },
  watch: {
    tasks: function (resultTasks) {
      if (resultTasks.length == 0) {
        this.tasksRender = []
      }
      else {
        var final_array = []
        for (var i = 0; i < resultTasks.length; i++) {
          final_array.push(
            {
              'id': resultTasks[i].id,
              'mission_id': resultTasks[i].mission,
              'mission_name': resultTasks[i].mission_name,
              'date': resultTasks[i].date,
              'hours': resultTasks[i].hours,
              'minutes': resultTasks[i].minutes,
              'description': resultTasks[i].description,
            }
          )
        }
        this.tasksRender = final_array
      }
    },
    lastWeekTasks: function (newLastWeekTasks) {
      // LAST WEEK ARRAY
      var lastWeekTasksRender = {}
      this.lastWeekTasksRenderDates = []
      this.lastWeekTasksRenderValues = []

      // LAST WEEK TASK MISSION DOGHNUT
      var lastWeekTasksMissionHoursRender = {}
      var lastWeekTasksMissionMinutesRender = {}
      this.lastWeekTasksDoghnutRenderMission = []
      this.lastWeekTasksDoghnutRenderTime = [{
        label: 'Répartition par mission',
        borderWidth: 2,
        data: [],
        backgroundColor: []
      }]

      // LAST WEEK TASK MISSION BAR
      var lastWeekTasksBarRenderMissionTime = {}
      this.lastWeekTasksBarChartRenderTime = []

      // Create empty table
      for (var i = 0; i < 7; i++) {
        var adaptedDate = this.$dayjs(this.filterTasksDate)
        lastWeekTasksRender[adaptedDate.subtract((6 - i), 'days').format('YYYY-MM-DD')] = {'hours': 0, 'minutes': 0}
      }

      // Iterate through data
      for (var j = 0; j < newLastWeekTasks.length; j++) {
        // LAST WEEK ARRAY
        lastWeekTasksRender[newLastWeekTasks[j].date].hours += newLastWeekTasks[j].hours
        lastWeekTasksRender[newLastWeekTasks[j].date].minutes += newLastWeekTasks[j].minutes

        // LAST WEEK TASK MISSION DOGHNUT
        if (lastWeekTasksMissionHoursRender[newLastWeekTasks[j].client_name + ' - ' + newLastWeekTasks[j].mission_name] == undefined) {
          lastWeekTasksMissionHoursRender[newLastWeekTasks[j].client_name + ' - ' + newLastWeekTasks[j].mission_name] = newLastWeekTasks[j].hours
          lastWeekTasksMissionMinutesRender[newLastWeekTasks[j].client_name + ' - ' + newLastWeekTasks[j].mission_name] = newLastWeekTasks[j].minutes
        }
        else {
          lastWeekTasksMissionHoursRender[newLastWeekTasks[j].client_name + ' - ' + newLastWeekTasks[j].mission_name] += newLastWeekTasks[j].hours
          lastWeekTasksMissionMinutesRender[newLastWeekTasks[j].client_name + ' - ' + newLastWeekTasks[j].mission_name] += newLastWeekTasks[j].minutes
        }

        // LAST WEEK TASK MISSION BAR
        if (lastWeekTasksBarRenderMissionTime[newLastWeekTasks[j].client_name + ' - ' + newLastWeekTasks[j].mission_name] == undefined) {
          lastWeekTasksBarRenderMissionTime[newLastWeekTasks[j].client_name + ' - ' + newLastWeekTasks[j].mission_name] = {}
          for (var tempDateKey in lastWeekTasksRender) {
            lastWeekTasksBarRenderMissionTime[newLastWeekTasks[j].client_name + ' - ' + newLastWeekTasks[j].mission_name][tempDateKey] = 0
          }
          lastWeekTasksBarRenderMissionTime[newLastWeekTasks[j].client_name + ' - ' + newLastWeekTasks[j].mission_name][newLastWeekTasks[j].date] = newLastWeekTasks[j].hours
        }
        else {
          lastWeekTasksBarRenderMissionTime[newLastWeekTasks[j].client_name + ' - ' + newLastWeekTasks[j].mission_name][newLastWeekTasks[j].date] += newLastWeekTasks[j].hours
        }
      }

      // Apply total duration function
      for (var k in lastWeekTasksRender){
        var durationResult = this.calculateTotalDuration(lastWeekTasksRender[k].hours, lastWeekTasksRender[k].minutes)
        if (durationResult.hours > 0 && durationResult.minutes == 0 ) {
          lastWeekTasksRender[k] = durationResult.hours + 'h'
        }
        else if (durationResult.hours == 0 && durationResult.minutes > 0) {
          lastWeekTasksRender[k] = durationResult.minutes + 'min'
        }
        else if (durationResult.hours == 0 && durationResult.minutes == 0) {
          lastWeekTasksRender[k] = 0
        }
        else {
          lastWeekTasksRender[k] = durationResult.hours + 'h' + durationResult.minutes
        }
      }

      // Create the final render variables for the array
      for (var dateKey in lastWeekTasksRender) {
        this.lastWeekTasksRenderDates.push(dateKey)
        this.lastWeekTasksRenderValues.push(lastWeekTasksRender[dateKey])
      }
      // Create the final render variables for the dognhut
      var doughnutBackgroundColorIndice = 0
      for (var missionKey in lastWeekTasksMissionHoursRender) {
        this.lastWeekTasksDoghnutRenderMission.push(missionKey)
        this.lastWeekTasksDoghnutRenderTime[0].data.push(lastWeekTasksMissionHoursRender[missionKey] + Math.round(lastWeekTasksMissionMinutesRender[missionKey] / 60))

        this.lastWeekTasksDoghnutRenderTime[0].backgroundColor.push(chartBackgroundColorsArray[doughnutBackgroundColorIndice])
        doughnutBackgroundColorIndice ++
        if (doughnutBackgroundColorIndice > chartBackgroundColorsArray.length - 1) {
          doughnutBackgroundColorIndice = 0
        }
      }

      // Create the final render varialbes for the bar char
      var barChartBackgroundColorIndice = 0
      for (var tempMission in lastWeekTasksBarRenderMissionTime) {
        this.lastWeekTasksBarChartRenderTime.push({
          'label': tempMission,
          'data': Object.values(lastWeekTasksBarRenderMissionTime[tempMission]),
          'backgroundColor': chartBackgroundColorsArray[barChartBackgroundColorIndice]
        })
        barChartBackgroundColorIndice ++
        if (barChartBackgroundColorIndice > chartBackgroundColorsArray.length - 1) {
          barChartBackgroundColorIndice = 0
        }
      }
    },
    missions: function (newMissions) {
      if (newMissions.length == 0) {
        this.missionsSelectOptions = []
      }
      else {
        var final_array = []
        for (var i = 0; i < newMissions.length; i++) {
          final_array.push(
            {
              'label': newMissions[i].client_name + ' - ' + newMissions[i].name,
              'value': newMissions[i].id,
            }
          )
        }
        this.missionsSelectOptions = final_array
      }
    },
    filterTasksDate: function (newFilterTasksDate) {
      var momentDate = this.$dayjs(newFilterTasksDate)
      if (newFilterTasksDate.length == 10 && momentDate.isValid() && isDate(newFilterTasksDate)) {
        this.getCollaborateurTasks()
        this.getCollaborateurLastWeekTasks()
      }
    }

  },
  methods: {
    // -------------- UTILS FUNCTION ----------------
    goToNextDay () {
      var day = this.$dayjs(this.filterTasksDate)
      var nextDay = day.add(1, 'days')
      this.filterTaskDay = nextDay.format('DD')
      this.filterTaskMonth = nextDay.format('MM')
      this.filterTaskYear = nextDay.format('YYYY')
    },
    goToPreviousDay () {
      var day = this.$dayjs(this.filterTasksDate)
      var previousDay = day.subtract(1, 'days')
      this.filterTaskDay = previousDay.format('DD')
      this.filterTaskMonth = previousDay.format('MM')
      this.filterTaskYear = previousDay.format('YYYY')
    },
    goToday () {
      var today = this.$dayjs()
      this.filterTaskDay = today.format('DD')
      this.filterTaskMonth = today.format('MM')
      this.filterTaskYear = today.format('YYYY')
    },
    // -------------- GETTERS -----------------------
    connectedCollaborateurAllMissions () {
      this.isAllCollaborateurMissionsLoading = true
      apiUserConnected.connectedCollaborateurAllMissions(this.token)
      .then((result) => {
        this.missions = result.data
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isAllCollaborateurMissionsLoading = false
      })
    },
    getCollaborateurTasks () {
      this.isAllCollaborateurTasksLoading = true
      apiUserConnected.getCollaborateurTasks(this.token, this.filterTasksDate)
      .then((result) => {
        this.tasks = result.data
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isAllCollaborateurTasksLoading = false
      })
    },
    getCollaborateurLastWeekTasks () {
      this.isAllCollaborateurLastWeekTasksLoading = true
      apiUserConnected.getCollaborateurLastWeekTasks(this.token, this.filterTasksDate)
      .then((result) => {
        this.lastWeekTasks = result.data
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isAllCollaborateurLastWeekTasksLoading = false
      })
    },

    getConnectedCollaborateur () {
      this.isProfileLoading = true
      apiUserConnected.getConnectedCollaborateur(this.token)
        .then((result) => {
          this.managerAlertMessage = result.data.manager_alert
        })
        .catch(() => {
          this.$store.commit('openGlobalErrorModal')
        })
        .finally(() => {
          this.isProfileLoading = false
        })
    },


    // ------------ ADD TASK --------------
    addTask (mission_id, hours, minutes, description) {
      this.addTaskInProcess = true
      this.addTaskButtonText = "Ajout en cours"
      this.addTaskButtonStyle = 'secondary'
      apiUserConnected.addTask(this.token,
        mission_id, this.filterTasksDate,
        hours, minutes, description)
      .then(() => {
        this.getCollaborateurTasks()
        this.getCollaborateurLastWeekTasks()
        this.newTasks.hours = ''
        this.newTasks.minutes = ''
        this.newTasks.description = ''
        this.isNewTaskAddingBigDescription = false
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.addTaskInProcess = false
        this.addTaskButtonText = "Ajouter une tâche"
        this.addTaskButtonStyle = 'outline-dark'
      })
    },


    // ---------- MODIFY TASK ------------
    showModifyTask(item) {
      this.modifyTaskId = item.id
      var date = this.$dayjs(item.date, 'YYYY-MM-DD')
      this.modifyTaskDateYear = date.format('YYYY')
      this.modifyTaskDateMonth = date.format('MM')
      this.modifyTaskDateDay = date.format('DD')
      this.modifyTaskMission = item.mission_id
      this.modifyTaskHours = item.hours
      this.modifyTaskMinutes = item.minutes
      this.modifyTaskDescription = item.description
      this.isTaskModifying = true
    },
    modifyTask () {
      this.modifyTaskButtonText = 'Modification en cours'
      this.modifyTaskButtonStyle = 'secondary'
      this.modifyTaskInProcess = true
      apiUserConnected.modifyTask(this.token,
        this.modifyTaskId, this.modifyTaskMission, this.modifyTaskDateFormat, this.modifyTaskHours, this.modifyTaskMinutes,
        this.modifyTaskDescription)
      .then(() => {
        this.getCollaborateurTasks()
        this.getCollaborateurLastWeekTasks()
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isTaskModifying = false
        this.modifyTaskButtonText = 'Modifier votre tâche'
        this.modifyTaskButtonStyle = 'outline-dark'
        this.modifyTaskInProcess = false
      })
    },

    // --------------- DELETE TASK
    deleteTask (task_id) {
      this.deleteTaskButtonText = 'Suppression en cours'
      this.deleteTaskButtonStyle = 'secondary'
      this.deleteTaskInProcess = true
      apiUserConnected.deleteTask(this.token, task_id)
      .then(() => {
        this.getCollaborateurTasks()
        this.getCollaborateurLastWeekTasks()
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.deleteTaskButtonText = 'Supprimer'
        this.deleteTaskButtonStyle = 'outline-danger'
        this.deleteTaskInProcess = false
      })
    },
  }
}
</script>

<style>
.no-border-table td {
  border-top: None;
}

.no-border-table th {
  border-top: None;
  border-bottom: None !important;
}


.no-margin-bottom-table {
  margin-bottom: 0px !important;
}

.thead-clickable {
  cursor: pointer;
}

.thead-clickable:hover{
  color: black !important;
}

</style>
